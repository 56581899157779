
















import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DataTable: () =>
      import("@/components/DataTableFilterDieta/DataTableFilterAlimentos.vue"),
  },
})
export default class Alimentos_lista extends Vue {
  //public ColumnasAlimentos: ClassColumnDataTable[] = [];

  created() {
    alimentoModule.getall_alimentos({
      id_dieta: -1,
      id_paciente: -1,
    });
  }

  public get datasource() {
    return alimentoModule.all_alimentos;
  }

  public get Columns() {
    var ColumnasAlimentos = [];
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_gr",
        "% gr.",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_glucidos",
        "% Hc",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_protein",
        "% Prt.",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    return ColumnasAlimentos;
  }

  public add_alimento(e: number) {
    this.$emit(
      "add",
      this.datasource.find((x) => x.id_estructura === e)
    );
  }
}
